import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import Particles from 'react-particles-js'
import Helmet from 'react-helmet'
import Fade from 'react-reveal/Fade'
import cn from 'classnames'
import { PushSpinner } from 'react-spinners-kit'
import LoadableVisibility from 'react-loadable-visibility/react-loadable'

import PrimaryLayout from '../layouts/PrimaryLayout'

import LandingIcon from '../icons/Landing'
import Button from '@material-ui/core/Button'

import Classes from '../../styles/classes'
import i18next from '../../config/i18n/context'

const styles = StyleSheet.create({
  header: {
    minHeight: '80vh',
    background: 'radial-gradient(ellipse at bottom, #002337 0%, #002f4b 100%)',
    overflowY: 'visible',
    position: 'relative'
  },
  headerText: {
    color: '#fff'
  },
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  }
})

const Loading = ({ pastDelay }) => {
  console.log('pastDelay ', pastDelay)
  if (pastDelay) {
    return (
      <div style={{ minHeight: '800px' }}>
        <PushSpinner size={30} color="#686769" loading={true} />
      </div>
    )
  }
  return null
}

const delay = 60

const Agence = LoadableVisibility({
  loader: () => import('./Agence'),
  loading: Loading,
  delay
})
const Services = LoadableVisibility({
  loader: () => import('./Services'),
  loading: Loading,
  delay
})
const References = LoadableVisibility({
  loader: () => import('./References'),
  loading: Loading,
  delay
})

class IndexPage extends Component {
  componentDidMount() {
    Scroll.scrollSpy.update()

    let hash = window.location.hash
    hash = hash.replace('#', '')

    if (hash && hash != '') {
      console.log(`hash : ${hash}`)
      Scroll.scroller.scrollTo(hash)
    }
  }

  render() {
    const lang =
      i18next.language ||
      (typeof window !== 'undefined' && window.localStorage.i18nextLng)

    return (
      <StaticQuery
        query={graphql`
          query HeaderQuery {
            allContentfulServices(
              filter: { node_locale: { eq: "fr" } }
              sort: { fields: [position], order: ASC }
            ) {
              edges {
                node {
                  id
                  titre
                  permalink
                  position
                }
              }
            }
          }
        `}
        render={data => {
          const Buttons = data.allContentfulServices.edges.map(
            ({ node }, i) => (
              <Link to={node.permalink} key={node.id} title={node.titre}>
                <Button
                  className={css(
                    i === 0 ? Classes.buttonPrimary : Classes.buttonSecondary,
                    styles.button
                  )}
                >
                  {node.titre}
                </Button>
              </Link>
            )
          )
          return (
            <PrimaryLayout>
              <Helmet
                title="Innosmile - Agence de communication digitale à Bruxelles"
                meta={[
                  {
                    name: 'description',
                    content:
                      'Innosmile est une agence de communication digitale, située à Bruxelles, spécialisée en création graphique, services web et publicité sur écrans'
                  },
                  { name: 'charset', content: 'UTF-8' },
                  {
                    name: 'viewport',
                    content:
                      'width=device-width, initial-scale=1, shrink-to-fit=no'
                  }
                ]}
              />
              <header className={css(styles.header)}>
                <Particles
                  style={{ position: 'absolute' }}
                  params={{
                    particles: {
                      number: {
                        value: 200,
                        density: {
                          enable: false
                        }
                      },
                      size: {
                        value: 3,
                        random: true,
                        anim: {
                          speed: 4,
                          size_min: 0.3
                        }
                      },
                      line_linked: {
                        enable: false
                      },
                      move: {
                        random: true,
                        speed: 1,
                        direction: 'top',
                        out_mode: 'out'
                      }
                    },
                    interactivity: {
                      events: {
                        onhover: {
                          enable: true,
                          mode: 'bubble'
                        },
                        onclick: {
                          enable: true,
                          mode: 'repulse'
                        }
                      },
                      modes: {
                        bubble: {
                          distance: 250,
                          duration: 2,
                          size: 0,
                          opacity: 0
                        },
                        repulse: {
                          distance: 400,
                          duration: 4
                        }
                      }
                    }
                  }}
                />
                <div className="content">
                  <div className="container" style={{ padding: '10em 3em' }}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-8 col-xs-12">
                        <div className="header-text">
                          <h1 className={css(styles.headerText)}>
                            <Fade top>
                              Votre agence de communication digitale
                            </Fade>
                          </h1>
                          <Fade>
                            <p className={css(styles.headerText)}>
                              Vous souhaitez offrir à votre entreprise une
                              communication digitale professionnelle et
                              cohérente débouchant sur des résultats mesurables
                              ? Notre ambition est de transformer votre volonté
                              en réalité.
                            </p>
                          </Fade>
                        </div>
                        <div className="button">{Buttons}</div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-8 col-xs-12">
                        <Fade big>
                          <LandingIcon />
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                  className="svg-separator"
                >
                  <polygon fill="#F6F9FF" points="0,100 100,0 100,100" />
                </svg>
              </header>

              <Scroll.Element id="agence" name="agence">
                <Agence />
              </Scroll.Element>
              <Scroll.Element id="services" name="services">
                <Services />
              </Scroll.Element>
              <Scroll.Element id="references" name="references">
                <References />
              </Scroll.Element>
            </PrimaryLayout>
          )
        }}
      />
    )
  }
}

export default IndexPage

/*
              <Scroll.Element id="blog" name="blog">
                <Blog />
              </Scroll.Element>*/
