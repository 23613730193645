import React, { Component } from 'react'

import { StyleSheet, css } from 'aphrodite'

import Layout from './Layout'
import Menu from './Menu'
import Footer from './Footer'

const scripts = []

const styles = StyleSheet.create({
  menuContainer: {}
})

class PrimaryLayout extends Component {
  render() {
    return (
      <Layout>
        <Menu isDisplayFromHome />

        {this.props.children}

        <Footer map contact />
      </Layout>
    )
  }
}

export default PrimaryLayout
